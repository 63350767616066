const refs = {
  titleAll: document.querySelectorAll(".article__title"),
  chapterAll: document.querySelectorAll(".article__chapter"),
  articleList: document.querySelector(".article__list"),
  articleBarLine: document.querySelector(".article__scrollbar--line"),
  article: document.querySelector(".article"),
  articleWrapper: document.querySelector(".article__wrapper"),
};

if (refs.titleAll && refs.article) {
  let element = refs.article.getBoundingClientRect();
  // const startPosition = refs.titleAll[0].getBoundingClientRect().top;
  let arrayValue = [];
  console.log(arrayValue);
  // refs.articleInnerAll.forEach(item => {
  //   item.dataset.value = item.getBoundingClientRect().height;
  // }),

  refs.titleAll.forEach(item => {
    // item.dataset.number array[1+i]
    // console.log(item.getBoundingClientRect().top)
    // console.log(startPosition)
    // let height = item.getBoundingClientRect().top - startPosition;
    let value = item.getBoundingClientRect().top;
    arrayValue.push(value);
    // let height = item.nextElementSibling.dataset.value;
    let markup = `<li data-value="${value}" class="article__item">${item.textContent}</li>`;
    refs.articleList.insertAdjacentHTML("beforeend", markup);
    // startPosition = refs.articleWrapper.getBoundingClientRect().height;

    // let height = item.closest(".article__chapter").offsetHeight;
    // let markup = `<li style="padding-bottom:${height / 9}px" class="article__item">${item.textContent}</li>`;
    // refs.articleList.insertAdjacentHTML("beforeend", markup);
  });

  document.querySelectorAll(".article__item").forEach((item, i) => {
    if (arrayValue[1 + i]) {
      item.style.paddingBottom = (arrayValue[1 + i] - item.dataset.value) / 9 + 'px';
    } else item.style.paddingBottom = (refs.articleWrapper.getBoundingClientRect().height - item.dataset.value) / 9;
  });

  window.addEventListener("scroll", e => {
    if (element.top <= window.pageYOffset && element.top + element.height >= window.pageYOffset) {
      let lineHeight = window.pageYOffset / 6.2;
      refs.articleBarLine.style.height = lineHeight + 'px';
      document.querySelectorAll(".article__item").forEach(item => {
        if (item.offsetTop <= lineHeight && lineHeight <= item.offsetTop + item.offsetHeight) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });
    }
    if (element.top > window.pageYOffset) {
      refs.articleBarLine.style.height = `0`;
    }
  });

  // window.addEventListener("scroll", e => {
  //   if (element.top <= window.pageYOffset && element.top + element.height >= window.pageYOffset) {
  //     let lineHeight = window.pageYOffset / 6.2;
  //     refs.articleBarLine.style.height = lineHeight;
  //     document.querySelectorAll(".article__item").forEach(item => {
  //       if (item.offsetTop <= lineHeight && lineHeight <= item.offsetTop + item.offsetHeight) {
  //         item.classList.add("active");
  //       } else {
  //         item.classList.remove("active");
  //       }
  //     });
  //   }
  //   if (element.top > window.pageYOffset) {
  //     refs.articleBarLine.style.height = `0`;
  //   }
  // });
}
