class ViewportIn {
  check(element) {
    const rect = element.getBoundingClientRect();
    if (rect.top >= 0) {
      return rect.top <= (window.innerHeight || document.documentElement.clientHeight);
    } else if (rect.bottom >= 0) {
      return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    }
  }
}

export default ViewportIn;
