import ViewportIn from "..//../function/viewport-in";

class IncreaseNumber {
  init() {
    const numberElements = document.querySelectorAll(".areas__value");
    // const canItems =document.querySelectorAll('.canvas__wrapper');
    // canItems.forEach(item=>{
    //   const viewportIn = new ViewportIn();
    //   const value = item.nextElementSibling.dataset.value;
    //   const can = item.querySelector('.areas__canvas');
    //   const itemPending = Number(item.nextElementSibling.getAttribute("data-pending"));

    //   if (viewportIn.check(item) && !itemPending) {
    //     this.onload(can, value)

    //   }
    // })

    if (numberElements) {
      let _this = this;

      this.initBody(this);
      window.addEventListener("scroll", function (e) {
        _this.initBody(_this);
      });
    }
  }

  initBody(parent) {
    const numberElements = document.querySelectorAll(".areas__value");
    const viewportIn = new ViewportIn();
    const canItems = document.querySelectorAll(".canvas__wrapper");

    canItems.forEach((item) => {
      const value = item.nextElementSibling.dataset.value;
      const can = item.querySelector(".areas__canvas");
      const bubble = item.querySelector(".areas__bubble");
      const itemPending = Number(item.nextElementSibling.getAttribute("data-pending"));
      const valueRotate = Number((value * 360) / 100);
      if (viewportIn.check(item) && !itemPending && !item.hasAttribute("data-start")) {
        item.setAttribute("data-start", 1);
        parent.onload(can, value);
        parent.start(bubble, valueRotate);
      }
    });
    numberElements.forEach((item) => {
      let itemID = item.getAttribute("id"),
        itemPending = Number(item.getAttribute("data-pending"));

      if (viewportIn.check(item) && !itemPending) {
        item.setAttribute("data-pending", "1");
        parent.increaseElement(itemID);
      }
    });
  }

  speed = 30;

  increaseElement(id) {
    let element = document.getElementById(id),
      endNumber = Number(element.getAttribute("data-value"));
    this.increase(0, endNumber, element);
  }

  increase(i, endNumber, element) {
    let _this = this;
    if (i <= endNumber) {
      let inner = element.querySelector("span");
      inner.innerHTML = i;
      setTimeout(function () {
        _this.increase(i + 1, endNumber, element);
      }, _this.speed);
    } else {
      //   let parent = element.closest(".areas__item");
      //   if (parent) {
      //     parent.classList.add("ready");
      //   }
    }
  }

  //   initProgress(parent) {
  // console.log('hi')
  //     const canItems =document.querySelectorAll('.canvas__wrapper');
  //     const viewportIn = new ViewportIn();

  //     canItems.forEach(item=>{
  //       const value = item.nextElementSibling.dataset.value;
  //       const can = item.querySelector('.areas__canvas');
  //       const itemPending = Number(item.nextElementSibling.getAttribute("data-pending"));

  //       if (viewportIn.check(item) && !itemPending) {
  //         parent.onload(can, value)

  //       }
  //     })
  //   }

  onload(can, value) {
    let c = can.getContext("2d");
    var fps = 8,
      oneProcent = 360 / 100,
      result = oneProcent * value;

    c.lineCap = "round";
    arcMove();

    function arcMove() {
      var deegres = 0;
      var acrInterval = setInterval(function () {
        deegres += 1;
        c.clearRect(0, 0, 100, 100);

        c.beginPath();
        c.arc(50, 50, 45, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + 360));
        c.strokeStyle = "#D1DDF0";
        c.lineWidth = "1";
        c.stroke();

        c.beginPath();
        c.strokeStyle = "#4D8AFF";
        c.lineWidth = "5";
        c.arc(50, 50, 45, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + deegres));
        c.stroke();
        if (deegres >= result) clearInterval(acrInterval);
      }, fps);
    }
  }

  start(bubble, value) {
    let runner;
    let degrees = 0;
    runner = setInterval(function () {
      if (degrees <= value) {
        bubble.style.transform = "rotate(" + degrees + "deg)";
        degrees++;
      }
      if (degrees > value) {
        clearInterval(runner);
      }
    }, 8);
  }
}

export default IncreaseNumber;
