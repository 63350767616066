import Swiper from "swiper/bundle";

class Slider {
  init() {
    this.swiper();
  }

  swiper() {
    const draggable = document.querySelector(".reviews__slider");

    if (draggable) {
      let draggableSwiper = new Swiper(".reviews__slider", {
        slidesPerView: 1,
        grabCursor: false,
        centeredSlides: true,
        // freeMode: true,
        spaceBetween: 25,
        navigation: {
          nextEl: ".swiper__button--next",
          prevEl: ".swiper__button--prev",
        },
      });
    }
  }
}

export default Slider;
