const refs = {
  modalOpenAll: document.querySelectorAll("button[data-modal-open]"),
  modalClose: document.querySelector("button[data-modal-close]"),
  backdrop: document.querySelector(".backdrop"),
  body: document.querySelector("body"),
  header: document.querySelector("header"),
  main: document.querySelector("main"),
};

export default function actionPopUp() {
  if (refs.backdrop) {
    if (!refs.body.classList.contains("show-modal")) {
      refs.modalOpenAll.forEach((modalOpen) => {
        modalOpen.addEventListener("click", () => {
          refs.body.classList.add("show-modal");
          refs.header.classList.add("filter");
          refs.main.classList.add("filter");
        });
      });
    }
    refs.modalClose.addEventListener("click", clickBtnClose);
    refs.backdrop.addEventListener("click", onClickPopUp);
    document.addEventListener("keydown", onEscKeyDown);
  }
}

function clickBtnClose(e) {
  onClosePopUp();
}

function onClosePopUp() {
  window.removeEventListener("keydown", onEscKeyDown);
  refs.body.classList.remove("show-modal");
  refs.header.classList.remove("filter");
  refs.main.classList.remove("filter");
}
// Click Backdrop
function onClickPopUp(e) {
  if (e.target == e.currentTarget) {
    onClosePopUp();
  }
}

// keydown Esc
function onEscKeyDown(e) {
  if (e.code === "Escape") {
    onClosePopUp();
  }
}
