class Menu {
  init() {
    this.menu();
  }

  menu() {
    const refs = {
      iconBurger: document.querySelectorAll(".header__burger"),
      nav: document.querySelector(".nav"),
      body: document.querySelector("body"),
      header: document.querySelector('header'),
      main: document.querySelector('main'),
    };
    refs.iconBurger.forEach(item => {
      item.addEventListener("click", e => {
        if (e.currentTarget) {
          refs.nav.classList.toggle("active");
          refs.body.classList.toggle("no-scroll");
          refs.header.classList.toggle("filter");
          refs.main.classList.toggle("filter");
        }
      });
    });
  }
}

export default Menu;
