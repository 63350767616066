// import Bootstrap
import 'bootstrap';

// import template styles
import './scss/main.scss'

import './js/components/article__scrollbar/scrollbar'

import actionPopUp from './js/components/pop-up/pop-up'

actionPopUp()

import Menu from './js/components/menu/menu'
const menu = new Menu()
menu.init()

import Slider from './js/components/swiper/swiper'
const swiper = new Slider()
swiper.init()

import IncreaseNumber from './js/components/increase-number/increase-number'
const increaseNumber = new IncreaseNumber()
increaseNumber.init()